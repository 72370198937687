<template>
  <div
    class="overlay back"
    @click="back"
  >
    <div>
      <img src="/asserts/icon/map-icon-back-normal.svg">
    </div>
    <h2 v-if="title">
      {{ title }}
    </h2>
  </div>
</template>

<script>
export default {
  name: 'BackButton',
  props: {
    missionName: {
      required: false,
      type: String,
      default: null,
    },
    label: {
      required: false,
      type: String,
      default: null,
    },
  },
  computed: {
    title() {
      if (this.missionName) {
        return this.missionName;
      }
      return this.label;
    },
  },
  methods: {
    back() {
      this.$emit('back-click');
    },
  },
};
</script>

<style lang="scss">
.overlay.back {
  cursor: pointer;
  top: 14px;
  left: 14px;

  * {
    float: left;
  }

  h2 {
    min-width: 100px;
    font-size: 18px;
    margin: 0px 12px;
    font-weight: normal;
    padding: 8px;
    border-radius: 4px;
    color: rgba(255, 255, 255, 0.9);
    background-color: rgba(0, 0, 0, 0.5);
    display: inline-block;
  }

  height: 32px;
}
</style>
