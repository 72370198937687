import { appAxios as axios } from '@/services/api/';

const apiUrl = `/v1/groups`;

export default {
  getAll(group) {
    return axios
      .get(`${apiUrl}/${group}/nests`)
      .then((response) => {
        return response.data;
      });
  },
  get(group, id) {
    return axios.get(`${apiUrl}/${group}/nests/${id}`).then((response) => {
      return response.data;
    });
  },
  create(group, data) {
    return axios.post(`${apiUrl}/${group}/nests`, data).then((response) => {
      return response.data;
    });
  },
  update(group, id, data) {
    return axios.put(`${apiUrl}/${group}/nests/${id}`, data).then((response) => {
      return response.data;
    });
  },
  delete(group, id) {
    return axios.delete(`${apiUrl}/${group}/nests/${id}`).then((response) => {
      return response.data;
    });
  },
  execute(group, nestId, command) {
    return axios.post(`${apiUrl}/${group}/nests/${nestId}/execute`, { command }).then((response) => {
      return response.data;
    });
  },
};
